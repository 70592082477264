if ($('#contact').length) {
	// Google Recaptcha v3
	grecaptcha.ready(function () {
		$('form.mod-recaptcha').each(function () {
			var object = $(this);
			grecaptcha.execute('6LfoDK0ZAAAAAG3JxMZlGnyi5UGXIOlmqTFdo5cj', { action: 'homepage' }).then(function (token) {
				$('<input>').attr({
					type: 'hidden',
					name: 'g-recaptcha-response',
					value: token
				}).appendTo(object)
			});
		});
	});
}



$(window).on('load resize', function () {
	var headerHeight = $('.header-wrap').outerHeight();

	$('body').css('padding-top', headerHeight);
});

//hide cookie policy
$(".section-cookies .btn-accept").on("click", function (e) {
	e.preventDefault();

	// If no validation errors
	$.ajax({
		method: "POST",
		url: "api/cookies/",
		data: {
			'cookies-accepted': 1,
		}
	}).done(function (response) {
		$('.section-cookies').addClass('mod-hide');

		var headerHeight = $('.header-wrap').outerHeight();

		$('body').css('padding-top', headerHeight);

	});
});

$(".section-cookies .btn-decline").on("click", function (ev) {
	ev.preventDefault();

	$.ajax({
		method: "POST",
		url: "api/cookies/",
		data: {
			'cookies-accepted': 0,
		}
	}).done(function (response) {
		$('.section-cookies').addClass('mod-hide');

		var headerHeight = $('.header-wrap').outerHeight();

		$('body').css('padding-top', headerHeight);
	});
});

// Ajax contact form processing
$('#contact form').submit(function (e) {
	e.preventDefault();

	// if honeypot field is filled in by spambot, do not submit
	if ($('input#Form_Email').val().length != 0) {
		return false;
	}

	$.ajax({
		method: 'POST',
		url: 'process/contact/',
		data: $(this).serialize()
	}).done(function (response) {

		// If no errors
		if (response == 'success') {
			// Reset form completely
			$('#contact form').hide().trigger('reset');

			// Show thank you message
			$('#contact .sub-thanks').fadeIn();

			// Remove any errors that may still be there
			$('#contact form .sub-errors').html('');

			// Hide thank you message after 10 seconds and return to form
			setTimeout(function () {
				$('#contact form').fadeIn();
				$('#contact .sub-thanks').hide();
			}, 6000);

			$('html, body').animate({ scrollTop: 0 }, 1000, 'swing');

			_gaq.push(['_trackEvent', 'Contact Us', 'Submitted']);
		}
		else {
			$('#contact form .sub-errors').fadeIn().html(response);

			$('html, body').animate({ scrollTop: 0 }, 1000, 'swing');
		}
	});
});


$('.section-cookies .btn-decline').on('click', (ev) => {
	ev.preventDefault();

	$('.section-cookies').slideToggle();
});

$('.aside-filters input[type="checkbox"]').change(function () {
	$('.loading_overlay').fadeTo(300, 0.5);

	var loc = window.location.pathname;

	$('.aside-filters input[type="checkbox"]:checked').each(function (i) {
		var separator = loc.indexOf('?') !== -1 ? "&" : "?";

		if ($(this).prop('checked')) {
			loc += separator + $(this).attr("name") + "=" + $(this).attr("value");
		}

		// // Analytics Push for Ajax call
		// if (loc == '/products/fixed-gas-detectors/?filters[]=19' || loc == '/products/portable-gas-detectors/?filters[]=19' || loc == '/products/analysers/?filters[]=19') {
		// 	_gaq.push(['_trackPageview', loc]);
		// }
	});

	history.pushState(null, '', loc);

	$.ajax({
		url: 'api/filters',
		type: "GET",
		data: $('.aside-filters input[type="checkbox"]').serialize() + '&category=' + $('body').attr('id')
	}).done(function (response) {
		if (!response) {
			$('.list-products').fadeIn().html('<h2 class="sub-no-products">There are no products under the filters you have chosen. Please try again.</h2>')
		} else {
			$('.list-products').fadeIn().html(response);
		}
	});


	//hide loading overlay
	$('.loading_overlay').fadeOut(300);
});

$('.aside-filters .sub-reset').on('click', function (ev) {
	ev.preventDefault();

	$('.loading_overlay').fadeTo(300, 0.5);

	$('.aside-filters input[type="checkbox"]:checked').prop('checked', false);

	var loc = window.location.pathname;

	history.pushState(null, '', loc);

	$.ajax({
		url: 'api/filters',
		type: "GET",
		data: $('.aside-filters input[type="checkbox"]').serialize() + '&category=' + $('body').attr('id')
	}).done(function (response) {
		$('.list-products').fadeIn().html(response);
	});

	//hide loading overlay
	$('.loading_overlay').fadeOut(300);
});

// Filtering section for media centre
if ($('#software').length) {
	$('.software-checkbox').change(function () {
		// Set checked var to 0
		var checked = 0;

		$(this).parent('li').toggleClass('on');

		$('.list-software li:not(.mod-head)').hide();

		// Loop through each checkbox
		$('.software-checkbox:checked').each(function () {
			// Add to checked var
			// value += $(this).val() + '[#]';
			checked++;

			$('.list-software li[data-cat="' + $(this).val() + '"]').show();
		});

		if (checked == 0) {
			$('.list-software li').show();
		}
	});
}

// Product Detail gallery - pops out modal based on image clicked via magnifying glass
const modal = document.getElementById('modal');

// If it's the product detail page then run script
if ($('.products').length) {

	setTimeout(() => {
		$('.aside-images').addClass('load');
	}, 150);

	// If magnifying glass has been clicked
	$('.list-image-gallery .sub-product-image .sub-zoom').click(function (e) {
		// Prevent default action
		e.preventDefault();

		// Grab image url via clicked image data attribute
		const image = $(this).data('modal-img'),
			imageAlt = $(this).data('modal-img-alt')

		// Add open class to modal to reveal
		modal.classList.add("modal-open");

		// Make behind body content unscrollable
		$('body').addClass('noscroll');

		// Show enlarged image of clicked thumbnail
		$('.modal .modal-content').append('<img src="' + image + '" alt="' + imageAlt + '">');
	});
}

$('.sub-filter-btn-mob').click(function (ev) {
	ev.preventDefault();

	$('.aside-filters').addClass('mod-active');
	$('body').addClass('noscroll');
	$('.mod-close').addClass('mod-show');
});

$('.btn.mod-close').click(function (ev) {
	ev.preventDefault();

	$('.aside-filters').removeClass('mod-active');
	$('body').removeClass('noscroll');
	$('.sub-filter-type-title').not(".mod-hide").addClass('mod-hide');
	$('.mod-close').removeClass('mod-show');
});

// Close modal when clicking on the 'X' button
$('.modal .modal-close').click(function () {
	// Remove image to stop flickering when opening another
	if ($('.products').length) {
		$('.modal .modal-content').html('');
	}

	// Remove open class to close
	modal.classList.remove("modal-open");

	// Make behind body content scrollable again
	$('body').removeClass('noscroll');
});

// Mobile Nav
$('.mob-hamburger').click(function (e) {
	e.preventDefault();

	$(this).toggleClass('mod-active');
	$('body').toggleClass('no-scroll');
	$('#nav.mod-mobile > ul').fadeToggle().toggleClass('mod-active');
	// $('.logo').fadeToggle();
});

// Mobile Nav Sub Menus
$('.mod-dropdown').click(function (e) {
	e.preventDefault();

	$(this).toggleClass('mod-active');
	$(this).next('.list-sub-nav').toggleClass('mod-active');
});



jQuery(document).ready(function ($) {
	search.load();
	gasSearch.load();
});

// Search
(function (search, $, undefined) {

	search.load = function () {
		$('body').on('keyup', '.sub-search-bar .searchterm', debounce(function (e) {
			$('.sub-search-bar .sub-loading').fadeTo(300, 0.5);

			// Clear outputs
			$('.sub-error').html('');
			$('.sub-results ul.list-results').html('');

			$.ajax({
				method: 'POST',
				url: 'api/search/',
				data: { 'searchterm': $(this).val() }
			}).done(function (response) {

				// Decode json response
				response = JSON.parse(response);

				// If we failed to retrieve any results
				if (response[0] == 'failure') {
					// Display error
					$('.sub-error').html(response['message']).addClass('mod-pad');
					$('.sub-results .list-results').removeClass('mod-show');
				}
				else {
					// Loop through results
					$.each(response, function (key, value) {
						// Output results as list items
						$('.sub-results ul.list-results').append('<li class="clearfix"><a class="title" href="' + value['link'] + '">' + value['title'] + '</a><span class="category">' + value['type'] + '</span></li>');
					});

					$('.sub-results .list-results').addClass('mod-show');
				}
			});
			$('.sub-search-bar .sub-loading').fadeOut(300);
		}, 1250));
	};
}(window.search = window.search || {}, jQuery));

// Search
(function (gasSearch, $, undefined) {

	gasSearch.load = function () {
		$('body').on('keyup', '.sub-keyword-wrap input', debounce(function (e) {

			if (!$(this).val()) {
				$('.sub-keyword-wrap .sub-error').hide();
			} else {
				$('.sub-keyword-wrap .sub-error').show();
			}

			// Clear outputs
			$('.sub-keyword-wrap .sub-error').html('').removeClass('mod-pad');
			$('.sub-keyword-wrap .sub-results ul.list-results').html('');

			$.ajax({
				method: 'POST',
				url: 'api/gasSearch/',
				data: { 'searchterm': $(this).val() }
			}).done(function (response) {

				// Decode json response
				response = JSON.parse(response);

				// If we failed to retrieve any results
				if (response[0] == 'failure') {
					// Display error
					$('.sub-keyword-wrap .sub-error').html(response['message']).addClass('mod-pad');
					$('.sub-keyword-wrap .sub-results .list-results').removeClass('mod-show');
				}
				else {
					// Loop through results
					$.each(response, function (key, value) {
						// Output results as list items
						$('.sub-keyword-wrap .sub-results ul.list-results').append('<li class="clearfix"><a class="title" href="' + value['link'] + '">' + value['name'] + '</a></li>');
					});

					$('.sub-keyword-wrap .sub-results .list-results').addClass('mod-show');
				}
			});
		}, 1250));
	};
}(window.gasSearch = window.gasSearch || {}, jQuery));

function debounce(func, wait, immediate) {
	var timeout;
	return function () {
		var context = this, args = arguments;
		var later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

// Slick carousel for news articles
$('ul.list-news').slick({
	asNavFor: '.sub-post-image ul.list-images',
	arrows: false,
	customPaging: function (slider, i) {
		return '<img class="mod-active" src="./assets/svg/slick-dots.svg" /><img class="mod-inactive" src="./assets/svg/slick-dot-inactive.svg" />';
	},
	dots: true,
	fade: true,
	slidesToScroll: 1,
	slidesToShow: 1,
	autoplay: true,
	autoplaySpeed: 6000,
	pauseOnFocus: false,
	pauseOnHover: false,
	fade: true,
	appendDots: $('.sub-post-image')
});

// Slick carousel for news article images
$('.sub-post-image ul.list-images').slick({
	arrows: false,
	asNavFor: 'ul.list-news',
	autoplay: true,
	autoplaySpeed: 6000,
	fade: true,
	focusOnSelect: true,
	infinite: true,
});


// Slick carousel config for product images
$('ul.list-image-gallery').slick({
	arrows: false,
	asNavFor: '.list-image-gallery-nav',
	fade: true,
	slidesToScroll: 1,
	slidesToShow: 1
});

$('ul.list-image-gallery-nav').slick({
	arrows: true,
	asNavFor: '.list-image-gallery',
	centerMode: true,
	centerPadding: '0',
	focusOnSelect: true,
	slidesToScroll: 1,
	slidesToShow: 3,
	prevArrow: $('.sub-prev'),
	nextArrow: $('.sub-next'),
});

// Show search bar when clicking search icon
$('.sub-search').click((e) => {
	// Prevent default action
	e.preventDefault();

	$('.sub-search').toggleClass('mod-active');
	$('.sub-search-bar').toggleClass('mod-show');
	$('.sub-searchform .searchterm').val('');
	$('body').toggleClass('noscroll');

	// Clear outputs
	$('.sub-error').html('');
	$('.sub-results ul.list-results').html('');
});

// Hide and show product filters
$('.sub-filter-type-title').click(function (e) {
	// Prevent default action
	e.preventDefault();

	$(this).toggleClass('mod-hide');
});

$('.section-news-list .list-posts li .sub-accordion').click(function (e) {
	// Prevent default action
	e.preventDefault();

	let $this = $(this);

	if ($this.hasClass('mod-active')) {
		$this.removeClass('mod-active');
	} else {
		$this.parent().parent().find('.sub-accordion').removeClass('mod-active');
		$this.addClass('mod-active');
	}

	if ($this.next().hasClass('mod-active')) {
		$this.next().removeClass('mod-active');
		$this.next().slideUp(350);
	} else {
		$this.parent().parent().find('li .sub-wrap').removeClass('mod-active');
		$this.parent().parent().find('li .sub-wrap').slideUp(350);
		$this.next().toggleClass('mod-active');
		$this.next().slideToggle(350);
	}
});

// Identify the device as touch device
function hasTouch() {
	return 'ontouchstart' in document.documentElement
		|| navigator.maxTouchPoints > 0
		|| navigator.msMaxTouchPoints > 0;
}

// Hack to remove all hover effects on touch devices
if (hasTouch()) { // remove all :hover stylesheets
	try { // prevent exception on browsers not supporting DOM styleSheets properly
		for (var si in document.styleSheets) {
			var styleSheet = document.styleSheets[si];
			if (!styleSheet.rules) continue;

			for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
				if (!styleSheet.rules[ri].selectorText) continue;

				if (styleSheet.rules[ri].selectorText.match('btn:hover')) {
					styleSheet.deleteRule(ri);
				}
			}
		}
	} catch (ex) { }
}

// Pops out share modal window
$(".social-share").click(function (e) {
	// Prevents default action
	e.preventDefault();
	// Runs window popup function
	windowPopup($(this).attr("href"), 500, 300);
});

// Google Maps
if ($('#map').length) {
	function createMap(el, lat, long, zoom, marker) {
		// Set a center point of the map, can be re-evaluated using `centerMap()`
		const center = { lat: lat, lng: long };

		const mapOptions = {
			zoom: zoom,
			center: center,
			fullscreenControl: false,
		};

		// Create a map instance.
		const melonMap = new MelonMap(document.getElementById(el), mapOptions);

		// melonMap.setMarkerImage('assets/svg/icon-map-pin.svg');

		// Marker locations [name, lat, long, zindex]
		const markerLocation = [['Weatherall', lat, long, 1]];

		if (marker == true) {
			markerLocation.forEach((markerLocation) => {
				const title = markerLocation[0];

				const location = {
					lat: parseFloat(markerLocation[1]),
					lng: parseFloat(markerLocation[2])
				}

				melonMap.addMarker(location, title);
			});
		}

		// Add a map style
		melonMap.setMapStyle(
			[{ "featureType": "all", "stylers": [{ "saturation": 0 }, { "hue": "#e7ecf0" }] }, { "featureType": "road", "stylers": [{ "saturation": -70 }] }, { "featureType": "transit", "stylers": [{ "visibility": "off" }] }, { "featureType": "poi", "stylers": [{ "visibility": "off" }] }, { "featureType": "water", "stylers": [{ "visibility": "simplified" }, { "saturation": -60 }] }],
			{ name: 'Styled Map' }
		);

		melonMap.panBy(0, -50);
	}

	// If the contact page then create the map with the following coordiantes
	if ($('#contact').length) {
		createMap('map', 51.762885296741366, -0.7494169253099365, 17, true);
	}
}


// Function for popout window
function windowPopup(url, width, height) {
	// Calculate the position for the popup so it’s centered on the screen.
	const left = (screen.width / 2) - (width / 2);
	const top = (screen.height / 2) - (height / 2);

	// opens a window with the given url & set position from above
	window.open(url, "", "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=" + width + ",height=" + height + ",top=" + top + ",left=" + left);
}

// Ensure the function exists on the page before we call it.
if (!!window['gdprSubscribeModal']) {
	// The form of which to attach the modal to.
	const formSelector = '#contact-form';
	// The url to link to for the privacy policy.
	const privacyPolicyURL = 'privacy-policy.html';
	// Color of the links in the modal
	const linkColour = '#000';
	// Color of the buttons in the modal.
	const buttonTextColour = '#000';

	// Call this per form.
	gdprSubscribeModal(formSelector, privacyPolicyURL, linkColour, buttonTextColour);
}